import Dialog from './dialog';
import { ActionButtons, type ActionButtonProps } from './dialog-action-buttons';

export type ActionDialogProps = Dialog & ActionButtonProps;

/**
 * A dialog with two buttons for the user to take an action to cancel or accept.
 */
export function ActionDialog(props: ActionDialogProps) {
  const { buttonOptions, onReset, onConfirm, onDismiss, ...dialogProps } = props;
  const bottomContent = (
    <ActionButtons
      buttonOptions={buttonOptions}
      onReset={onReset}
      onConfirm={onConfirm}
      onDismiss={onDismiss}
    />
  );
  return <Dialog {...dialogProps} onDismiss={onDismiss} bottomContent={bottomContent} />;
}
