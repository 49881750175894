import * as React from 'react';
import cx from 'classnames';

export type ControlCloseProps = {
  /** used for screen readers and other assistive devices */
  label: string;
  /** the size of the control */
  size?: number;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * A simple button to be used any time you need an interaction to close something. like a popup.
 */
export const ControlClose = React.forwardRef<HTMLButtonElement, ControlCloseProps>(
  ({ className, label, disabled, size = 16, ...rest }, forwardedRef) => (
    <button
      ref={forwardedRef}
      type="button"
      className={cx('btn btn-primary-text btn-lg', {
        [className as string]: !!className,
      })}
      disabled={disabled}
      {...rest}
    >
      <span className="sr-only">{label}</span>
      <svg role="img" aria-hidden viewBox="0 0 22 22" width={size} height={size}>
        <path
          d="M1.4 1.4l18.425 18.425M1.4 19.501L20.144 1.4"
          className={cx({
            'stroke-text-disabled': disabled,
            'stroke-text': !disabled,
          })}
          strokeWidth={2}
          fill="none"
          fillRule="evenodd"
          strokeLinecap="square"
        />
      </svg>
    </button>
  )
);

ControlClose.displayName = 'ControlClose';

export default ControlClose;
