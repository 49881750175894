import { isBrowser } from '@dx-ui/utilities-is-browser';
import type { Selection, ConstructorParams, Agent, Result, Status } from './conductrics';
import {
  isApiLoaded,
  getConductricsSelection,
  initConductrics,
  globalizeSelections,
} from './conductrics';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

export type ConductricsSelection = {
  selection: Selection | null;
  isLoaded: boolean;
  error: Error | null;
};

type AgentsSelection = Record<string, ConductricsSelection>;

type StateTransformer = (agentId: string) => ConductricsSelection;

type ConductricsResult = Result | null | undefined;

export type ClientState = {
  isInitialized: boolean;
  isLoading: boolean;
  error: null | Error;
};

const getInitKey = () => 'conductricsInit' + (isBrowser ? window.location.pathname : '');

const queryKeys = {
  initKey: getInitKey(),
  agentKey: 'conductricsSelections',
} as const;

const defaultClientState: ClientState = {
  isInitialized: false,
  isLoading: true,
  error: null,
};

function reduceAgents(
  agents: Agent[],
  stateOrTransformer: ConductricsSelection | StateTransformer
): AgentsSelection {
  return agents.reduce((acc, { agentId }) => {
    const state =
      typeof stateOrTransformer === 'function' ? stateOrTransformer(agentId) : stateOrTransformer;
    return { ...acc, [agentId]: state };
  }, {});
}

function useConductricsClientQuery() {
  return useQuery<ClientState>({
    queryKey: [getInitKey()],
    initialData: defaultClientState,
    gcTime: Infinity,
    staleTime: Infinity,
  });
}

function useConductricsSelectionQuery(agents: Agent[], status?: Status) {
  const { data: clientState } = useConductricsClientQuery();
  const {
    data,
    isSuccess,
    error: queryError,
    isLoading: queryIsLoading,
  } = useQuery<ConductricsResult, Error, ConductricsResult>({
    queryKey: [queryKeys.agentKey, agents, status],
    queryFn: () => getConductricsSelection(agents, status),
    enabled: clientState.isInitialized,
    retry: false,
  });

  useEffect(() => {
    if (isSuccess && data?.sels) {
      Object.entries(data.sels).forEach(([agentId, choice]) => {
        globalizeSelections(choice, agentId);
      });
    }
  }, [isSuccess, data]);

  const error = clientState.error || queryError;
  const isLoading = clientState.isLoading || (clientState.isInitialized && queryIsLoading);
  return { data, error, isLoading };
}

function useSelection(agents: Agent[], status?: Status) {
  const isSingleAgent = agents?.length === 1;
  const { data, error, isLoading } = useConductricsSelectionQuery(agents, status);

  const isLoaded = !isLoading;
  const defaultState: ConductricsSelection = { selection: null, isLoaded, error };
  const getSelection = (agentId: string) => {
    const item = data?.items?.find(({ a }) => a === agentId);
    return {
      choice: data?.sels?.[agentId],
      status: item?.s,
      meta: item?.md,
    };
  };

  // Bail early if the conductrics API is not available
  if (error) {
    return isSingleAgent ? defaultState : reduceAgents(agents, defaultState);
  }

  const selectedAgentsState = reduceAgents(agents, (agentId: string) => {
    const selection = getSelection(agentId);
    return { error, isLoaded, selection };
  });

  const agentsState: ConductricsSelection | AgentsSelection = isLoaded
    ? selectedAgentsState
    : reduceAgents(agents, defaultState);

  // If there's one selection, send back the specific agent's state due to the function signature
  const firstAgent = agents[0];
  return isSingleAgent && firstAgent ? agentsState[firstAgent?.agentId] : agentsState;
}

// Supports singular or multiple agents (such as MVT)
export function useConductricsSelection(agentId: string, status?: Status): ConductricsSelection;
export function useConductricsSelection(agents: Agent[]): AgentsSelection;
export function useConductricsSelection(agentId: string | Agent[], status?: Status) {
  const agents = typeof agentId === 'string' ? [{ agentId, status }] : agentId;
  return useSelection(agents, status);
}

export function useInitConductrics() {
  const client = useQueryClient();

  // Prime the cache
  useConductricsClientQuery();

  if (isBrowser) {
    if (window.conductricsSelsPath !== window.location.pathname) {
      window.conductricsSelsPath = window.location.pathname;
      window.conductricsSels = undefined;
    }
  }

  return (params?: ConstructorParams) => {
    if (isApiLoaded()) {
      initConductrics(params);
      client.setQueryData([getInitKey()], {
        isInitialized: true,
        isLoading: false,
        error: null,
      });
    } else {
      client.setQueryData([getInitKey()], {
        isInitialized: false,
        isLoading: false,
        error: new Error('api-load-error'),
      });
    }
  };
}
