import { useEffect } from 'react';
import * as React from 'react';
import { Header as SharedHeader } from '@dx-ui/osc-header';
import { useAuth } from '@dx-ui/framework-auth-provider';
import getConfig from 'next/config';
import { useEventMetrics, EventType } from '../../hooks';
import { useRouter } from 'next/router';
import { SessionContext, SessionContextType } from '../../lib';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { useIsClient } from 'usehooks-ts';

const Header: React.FC<React.PropsWithChildren<unknown>> = () => {
  const router = useRouter();
  const [isMetricsSent, setMetricSent] = React.useState(false);
  const { setSessionContext } = React.useContext(SessionContext);
  const language = router.locale;
  const { setEventMetrics } = useEventMetrics();
  const { isAuthenticated, guestId, guestInfo, isLoading, isCorpUser, login, logout } = useAuth();
  const isClient = useIsClient();

  const publicEnvVars = getConfig()?.publicRuntimeConfig || {};
  const DX_AUTH_UI = getConfig()?.publicRuntimeConfig?.DX_AUTH_UI;
  const OHW_BASE_URL: string | URL = publicEnvVars.OHW_BASE_URL || 'https://www.hilton.com/';
  const OHW_ORIGIN = new URL(OHW_BASE_URL).origin;
  const showSignInJoinLinks =
    isClient && !router.pathname.includes('/my-event/sign-in') && !isCorpUser;

  useEffect(() => {
    if (!isLoading && isAuthenticated && (guestId || -1) >= 0 && !isMetricsSent && guestInfo) {
      setMetricSent(true);
      setEventMetrics(EventType.LOG_IN, {
        hhonorsNumber: guestInfo?.hhonors?.hhonorsNumber || '',
        tierName: guestInfo?.hhonors?.summary?.tierName || '',
        totalPoints: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
        packages: (guestInfo?.hhonors?.packages || []) as unknown as string[],
      });
    }
  }, [isLoading, isAuthenticated, guestId, guestInfo, isMetricsSent, setEventMetrics]);

  const handleSignout = async () => {
    const route = router.route;
    await logout().finally(() => {
      switch (route) {
        case '/my-event/sign-in':
          setSessionContext(SessionContextType.SignOut);
          break;
        case '/attend-my-event/[event]':
          setSessionContext(SessionContextType.SignOutAndStayOnPage);
          break;
        default:
          setSessionContext(SessionContextType.ShowConfirmSignOut);
      }
      if (isBrowser && window?.sessionStorage) window.sessionStorage.clear();
    });
  };

  return (
    <SharedHeader
      user={
        isAuthenticated && guestInfo && !isCorpUser
          ? {
              name: guestInfo.personalinfo?.name?.firstName,
              honorsTier: guestInfo?.hhonors?.summary?.tierName || '',
              honorsPoints: guestInfo?.hhonors?.summary?.totalPoints ?? '',
              honorsPointsFmt: guestInfo?.hhonors?.summary?.totalPointsFmt || '',
            }
          : undefined
      }
      brand={{
        url:
          isClient && isCorpUser
            ? 'https://hilton.sharepoint.com/sites/LS_GlobalSales/SitePages/GME-Groups-Meetings-Events-Programs-Tools-Housing-Attendee-Website.aspx'
            : `${OHW_ORIGIN}/${language}/hilton-honors/guest/my-account/`,
        code: 'HH',
        name: 'Hilton Honors',
      }}
      onSignOut={handleSignout}
      onSignInAttempt={async (response) => {
        if (response?.data) {
          await login(response);
        }
      }}
      userLinks={{
        accountLink: {
          url: `${OHW_ORIGIN}/${language}/hilton-honors/guest/my-account/`,
        },
        ...(showSignInJoinLinks && {
          signInLink: {
            url: DX_AUTH_UI?.replace('__LANG__', language),
          },
          signUpLink: {
            url: `${OHW_ORIGIN}/${language}/hilton-honors/join/`,
          },
        }),
      }}
    />
  );
};

export default Header;
