import * as React from 'react';
import cx from 'classnames';

export type ControlBackProps = {
  /** used for screen readers and other assistive devices */
  label: string;
  /** the size of the control */
  size?: number;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * A simple button to be used any time you need an interaction to go back. like for pagination.
 */
export const ControlBack = React.forwardRef<HTMLButtonElement, ControlBackProps>(
  ({ className, disabled, label, size = 16, ...rest }, forwardedRef) => {
    return (
      <button
        ref={forwardedRef}
        type="button"
        className={cx('btn btn-primary-text btn-lg rtl:-scale-x-100 text-current', {
          [className as string]: !!className,
        })}
        disabled={disabled}
        {...rest}
      >
        <span className="sr-only">{label}</span>
        <svg role="img" aria-hidden viewBox="0 0 15 31" width={size} height={size}>
          <path
            className="stroke-current"
            d="M14 1L2 15l12 14"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeWidth={2}
          />
        </svg>
      </button>
    );
  }
);

ControlBack.displayName = 'ControlBack';

export default ControlBack;
