import { Fragment } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { Link } from '@dx-ui/osc-link';
import { Popup } from '@dx-ui/osc-popup';
import NavItemWithModal from './nav-item-with-modal';
import NavItemWithPopup from './nav-item-with-popup';
import Icon from '@dx-ui/osc-icon';
import { LoginIFrame } from '@dx-ui/osc-login';
import NavItemWithLink from './nav-item-with-link';
import type { TDrawerItem } from './brand-header-body';
import type { HeaderProps } from '../header';
import {
  sendInteractionReward,
  trackNavClickBasedOnLabel,
  trackNavClick,
} from '../header.utilities';
import { LanguageSelector } from '@dx-ui/osc-language-selector';
import { sendReward } from '@dx-ui/framework-conductrics';
import { Logo } from '../header.logo';

export type MegaMenuProps = Pick<
  HeaderProps,
  | 'isFluid'
  | 'suppressLogo'
  | 'brand'
  | 'findStayLink'
  | 'user'
  | 'languageSelectorOptions'
  | 'userLinks'
  | 'loginOptions'
  | 'onSignInAttempt'
  | 'onSignOut'
  | 'theme'
> & {
  mainNavLinks?: TDrawerItem[];
};

export function MegaMenu({
  isFluid,
  suppressLogo = false,
  brand,
  mainNavLinks,
  findStayLink,
  user,
  languageSelectorOptions,
  loginOptions,
  onSignInAttempt,
  userLinks,
  onSignOut,
  theme,
}: MegaMenuProps) {
  const userNavProps = {
    findStayLink,
    user,
    userLinks,
    onSignInAttempt,
    onSignOut,
    loginOptions,
    languageSelectorOptions,
    theme,
  };

  const isDark = theme === 'dark';

  return (
    <div className="bg-transparent">
      <div
        className={cx({
          'container-fluid': isFluid,
          container: !isFluid,
        })}
      >
        <div className="relative flex flex-wrap">
          {suppressLogo ? null : (
            <div className="flex min-h-[4.5rem] items-center">
              <Link
                url={brand.url}
                target={brand.target}
                onClick={sendInteractionReward}
                // once nav is implemented on all pages for OHW make w-72 for all brands per UX/UI
                anchorClassName="relative block h-14 w-28 brand-ou:lg:w-72 brand-lx:lg:w-72 me-1"
                showNewWindowIcon={false}
              >
                <Logo theme={theme} brand={brand} />
              </Link>
            </div>
          )}
          <div className="absolute end-0 top-0">
            <UserNav {...userNavProps} />
          </div>
        </div>
      </div>
      <div
        className={cx({
          'nav-bottom-link-area': !isDark,
          'nav-bottom-link-area-dark': isDark,
        })}
      >
        <div
          className={cx({
            'container-fluid': isFluid,
            container: !isFluid,
          })}
        >
          <div className="flex overflow-auto" data-testid="header-links-container">
            {mainNavLinks?.length ? <MainNav theme={theme} mainNavLinks={mainNavLinks} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

type UserNavProps = Pick<
  MegaMenuProps,
  | 'findStayLink'
  | 'loginOptions'
  | 'onSignInAttempt'
  | 'onSignOut'
  | 'user'
  | 'userLinks'
  | 'languageSelectorOptions'
  | 'theme'
>;

function UserNav({
  findStayLink,
  loginOptions,
  onSignInAttempt,
  onSignOut,
  user,
  languageSelectorOptions,
  userLinks,
  theme,
}: UserNavProps) {
  const [t] = useTranslation('osc-header');

  const defaultOptions = {
    frameSrc: userLinks?.signInLink?.url || '',
    title: t('signIn'),
    onLoginAttempt: onSignInAttempt,
  };

  const isDark = theme === 'dark';

  return (
    <nav className="relative ms-auto flex min-h-[4.5rem]" aria-label={t('hiltonHonors')}>
      <ul className="flex">
        {languageSelectorOptions ? (
          <li className="flex items-center pb-[0.3rem] pr-3">
            <LanguageSelector theme={theme} {...languageSelectorOptions} />
          </li>
        ) : null}
        {findStayLink ? (
          <NavItemWithLink
            onClick={() => {
              sendInteractionReward();
              sendReward('find-stay-click');
              trackNavClick('gh_findstay');
            }}
            url={findStayLink.url}
            isNewWindow={findStayLink.isNewWindow}
            showNewWindowIcon={false}
            className="flex"
            theme={theme}
          >
            <span className="nav-item-text self-center">{t('findStay')}</span>
            <Icon
              name="calendar"
              className={cx('ms-2 group-focus-within:hidden group-hover:hidden', {
                'nav-icon': !theme,
                'nav-icon-dark': isDark,
              })}
            />
            <Icon
              name="calendar"
              variant="solid"
              className={cx('ms-2 hidden group-focus-within:block group-hover:block', {
                'nav-icon': !theme,
                'nav-icon-active-dark': isDark,
              })}
            />
          </NavItemWithLink>
        ) : null}
        {user ? (
          <Popup id={`${user.name.replaceAll(' ', '-')}-popup`}>
            <NavItemWithPopup
              theme={theme}
              onClick={() => {
                trackNavClick('gh_membername');
                sendReward('profile-name-click');
              }}
              buttonChildren={
                <>
                  <span className="nav-item-text whitespace-nowrap text-sm">
                    {t('greetings', { username: user.name })}
                  </span>
                  <Icon
                    name="user-circle"
                    className={cx('ms-2 group-focus-within:hidden group-hover:hidden', {
                      'nav-icon': !theme,
                      'nav-icon-dark': isDark,
                    })}
                  />
                  <Icon
                    name="user-circle"
                    variant="solid"
                    className={cx('ms-2 hidden group-focus-within:block group-hover:block', {
                      'nav-icon': !theme,
                      'nav-icon-active-dark': isDark,
                    })}
                  />
                </>
              }
            >
              <div className="container flex px-12 py-3">
                <div className="flex items-center">
                  <div className="bg-hilton-alt flex size-20 items-center justify-center rounded-full">
                    <Icon name="user" size="4xl" />
                  </div>
                  <div className="ms-5">
                    <p className="brand-ey:font-normal mb-1 text-sm font-bold">
                      {t('greetings', { username: user.name })}
                    </p>
                    <p className="mb-1 capitalize">
                      <span className="brand-ey:font-normal text-sm font-bold underline">
                        {user.honorsTier}
                      </span>{' '}
                      {t('status')}
                    </p>
                    <p className="brand-ey:font-normal mb-1 text-sm">
                      {t('honorsPointsTotal', { honorsPointsTotal: user.honorsPointsFmt })}
                    </p>
                    {user.hhonorsNumber ? (
                      <p className="brand-ey:font-normal mb-1 text-sm">
                        {t('hiltonHonorsNumber', { hhonorsNumber: user.hhonorsNumber })}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="ms-10 w-3/5">
                  <ul className="flex w-full flex-row flex-wrap">
                    {userLinks?.accountLink ? (
                      <li className="basis-1/3">
                        <div
                          className={cx(
                            'border-border m-3 border-b-2 [&_a]:flex [&_a]:p-3',
                            'brand-ey:hover:bg-tertiary-alt brand-ey:hover:border-b-primary-alt',
                            'brand-ou:hover:bg-bg-light brand-ou:border-primary'
                          )}
                        >
                          <Link
                            url={userLinks?.accountLink.url}
                            underline={false}
                            className="whitespace-nowrap text-sm"
                            anchorClassName="nav-drawer-link hover:nav-drawer-link-active focus:nav-drawer-link-active brand-ey:focus:bg-tertiary-alt brand-ey:focus:border-b-primary-alt brand-ou:focus:bg-bg-light"
                            onClick={() => {
                              trackNavClick('gh_membername_hiltonhonorshome');
                            }}
                          >
                            {t('hiltonHonorsHome')}
                          </Link>
                        </div>
                      </li>
                    ) : null}
                    {userLinks?.additionalLinks?.length
                      ? userLinks?.additionalLinks.map(({ label, url }, index) =>
                          index === 1 ? (
                            <Fragment key={label}>
                              <li className="basis-1/3">
                                <div
                                  className={cx(
                                    'border-border m-3 border-b-2 hover:font-bold text-sm',
                                    'brand-ey:hover:font-normal brand-ey:hover:bg-tertiary-alt brand-ey:hover:border-b-primary-alt',
                                    'brand-ou:hover:bg-bg-light brand-ou:border-primary'
                                  )}
                                >
                                  <button
                                    className="nav-drawer-link hover:nav-drawer-link-active focus:nav-drawer-link-active brand-ey:focus:bg-tertiary-alt brand-ey:focus:border-b-primary-alt brand-ou:focus:bg-bg-light flex w-full whitespace-nowrap p-3"
                                    onClick={async () => {
                                      trackNavClick('gh_membername_signout');
                                      await onSignOut();
                                    }}
                                    type="button"
                                  >
                                    {t('signOut')}
                                  </button>
                                </div>
                              </li>
                              <li className="basis-1/3">
                                <div
                                  className={cx(
                                    'border-border m-3 border-b-2 hover:font-bold [&_a]:flex [&_a]:p-3',
                                    'brand-ey:hover:font-normal brand-ey:hover:bg-tertiary-alt brand-ey:hover:border-b-primary-alt',
                                    'brand-ou:border-primary'
                                  )}
                                >
                                  <Link
                                    url={url}
                                    underline={false}
                                    className="whitespace-nowrap text-sm"
                                    anchorClassName="nav-drawer-link hover:nav-drawer-link-active focus:nav-drawer-link-active brand-ey:focus:bg-tertiary-alt brand-ey:focus:border-b-primary-alt brand-ou:focus:bg-bg-light"
                                    onClick={() => {
                                      label && trackNavClickBasedOnLabel(label, 'membername');
                                    }}
                                  >
                                    {label}
                                  </Link>
                                </div>
                              </li>
                            </Fragment>
                          ) : (
                            <li key={label} className="basis-1/3">
                              <div
                                className={cx(
                                  'border-border m-3 border-b-2 hover:font-bold [&_a]:flex [&_a]:p-3',
                                  'brand-ey:hover:font-normal brand-ey:hover:bg-tertiary-alt brand-ey:hover:border-b-primary-alt',
                                  'brand-ou:hover:bg-bg-light brand-ou:border-primary'
                                )}
                              >
                                <Link
                                  url={url}
                                  underline={false}
                                  className="whitespace-nowrap text-sm"
                                  anchorClassName="nav-drawer-link hover:nav-drawer-link-active focus:nav-drawer-link-active brand-ey:focus:bg-tertiary-alt brand-ey:focus:border-b-primary-alt brand-ou:focus:bg-bg-light"
                                  onClick={() => {
                                    label && trackNavClickBasedOnLabel(label, 'membername');
                                  }}
                                >
                                  {label}
                                </Link>
                              </div>
                            </li>
                          )
                        )
                      : null}
                    {!userLinks?.additionalLinks?.length ||
                    userLinks?.additionalLinks?.length === 1 ? (
                      <li className="basis-1/3">
                        <div
                          className={cx(
                            'border-border m-3 border-b-2 hover:font-bold text-sm',
                            'brand-ey:hover:font-normal brand-ey:hover-bg-tertiary-alt',
                            'brand-ou:hover:bg-bg-light brand-ou:border-primary',
                            'brand-lx:hover:bg-bg-light'
                          )}
                        >
                          <button
                            className="hover:nav-drawer-link-active focus:nav-drawer-link-active brand-ey:focus:bg-tertiary-alt brand-ey:focus:border-b-primary-alt brand-ou:focus:bg-bg-light brand-lx:focus:bg-bg-light flex w-full whitespace-nowrap p-3"
                            onClick={async () => {
                              trackNavClick('gh_membername_signout');
                              await onSignOut();
                            }}
                            type="button"
                          >
                            {t('signOut')}
                          </button>
                        </div>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </NavItemWithPopup>
          </Popup>
        ) : userLinks?.signInLink || userLinks?.signUpLink ? (
          <>
            {userLinks.signUpLink ? (
              <>
                <li
                  className={cx('border-r-text ms-1 flex items-center', {
                    'nav-list-item': !theme,
                    'nav-list-item-dark': isDark,
                  })}
                >
                  <div className="flex h-full items-center pt-1 [&_a]:flex [&_a]:h-full [&_a]:items-center">
                    <Link
                      url={userLinks.signUpLink.url}
                      className="nav-item-text mx-1 text-sm"
                      underline={false}
                      isNewWindow={userLinks.signUpLink.isNewWindow}
                      showNewWindowIcon={false}
                      onClick={() => {
                        sendInteractionReward();
                        sendReward('nav-join-click');
                        trackNavClick('gh_join_signin');
                      }}
                    >
                      {t('signUp')}
                    </Link>
                  </div>
                </li>
                <span
                  aria-hidden={true}
                  className={cx(
                    'mx-1 inline-flex h-full items-center pb-1 text-sm font-bold brand-gu:pb-0',
                    { 'text-text-inverse': isDark }
                  )}
                >
                  |
                </span>
              </>
            ) : null}
            {userLinks?.signInLink ? (
              <li
                className={cx('flex items-center', {
                  'nav-list-item': !theme,
                  'nav-list-item-dark': isDark,
                })}
              >
                <NavItemWithModal
                  theme={theme}
                  onLoginAttempt={defaultOptions.onLoginAttempt}
                  title={defaultOptions.title}
                  onClick={() => {
                    trackNavClick('gh_join_signin');
                    sendReward('nav-sign-in-click');
                  }}
                  buttonChildren={
                    <div className="flex h-full items-center pt-1 [&_button]:flex [&_button]:h-full [&_button]:items-center">
                      <span className="nav-item-text items-center"> {t('signIn')}</span>
                      <Icon
                        name="user-circle"
                        className={cx('ms-2 group-focus-within:hidden group-hover:hidden', {
                          'nav-icon': !theme,
                          'nav-icon-dark': isDark,
                        })}
                      />
                      <Icon
                        name="user-circle"
                        variant="solid"
                        className={cx('ms-2 hidden group-focus-within:block group-hover:block', {
                          'nav-icon': !theme,
                          'nav-icon-active-dark': isDark,
                        })}
                      />
                    </div>
                  }
                >
                  <LoginIFrame data-e2e="loginIframe" {...defaultOptions} {...loginOptions} />
                </NavItemWithModal>
              </li>
            ) : null}
          </>
        ) : null}
      </ul>
    </nav>
  );
}

type MainNavProps = Required<Pick<MegaMenuProps, 'mainNavLinks'>> & {
  theme?: Exclude<CmsBrandComponentTheme, 'light'>;
};

function MainNav({ mainNavLinks, theme }: MainNavProps) {
  return (
    <div className="flex min-h-[4.5rem] items-center">
      <div className="size-full overflow-x-visible">
        <ul className="flex h-full items-center rtl:space-x-reverse">
          {mainNavLinks.map(({ label, subMenu, link, onClick, ...linkProps }) => {
            if (!subMenu?.length && link?.url) {
              return (
                <NavItemWithLink
                  className="nav-main-link"
                  key={label}
                  theme={theme}
                  onClick={() => {
                    sendInteractionReward();
                    trackNavClickBasedOnLabel(label);
                    onClick?.();
                  }}
                  {...linkProps}
                  url={link?.url}
                  isNewWindow={link.isNewWindow}
                >
                  {label}
                </NavItemWithLink>
              );
            }
            if (subMenu?.length) {
              return (
                <Popup
                  id={`${label.replaceAll(' ', '-').replaceAll('&', 'and')}-popup`}
                  key={label}
                >
                  <NavItemWithPopup
                    theme={theme}
                    onClick={() => {
                      trackNavClickBasedOnLabel(label);
                    }}
                    expandedButtonChildren={
                      <>
                        <span className="nav-main-link whitespace-nowrap text-sm">{label}</span>
                        <Icon name="arrowhead-small-up" />
                      </>
                    }
                    buttonChildren={
                      <>
                        <span className="nav-main-link whitespace-nowrap text-sm">{label}</span>
                        <Icon className="rotate-180" name="arrowhead-small-up" />
                      </>
                    }
                  >
                    <ul className="container flex flex-row flex-wrap px-12 py-3">
                      {subMenu.map(({ label: subMenuLabel, link, onClick }) => (
                        <li
                          key={subMenuLabel}
                          className={cx({
                            'basis-[35%]': subMenu.length < 5,
                            'basis-1/3': subMenu.length >= 5,
                          })}
                        >
                          <div
                            className={cx(
                              'border-border m-3 border-b-2 hover:font-bold [&_a]:flex [&_a]:p-3',
                              'brand-ey:hover:font-normal brand-ey:hover:bg-tertiary-alt brand-ey:hover:border-b-primary-alt',
                              'brand-ou:border-primary brand-ou:hover:bg-bg-light'
                            )}
                          >
                            <Link
                              url={link?.url}
                              isNewWindow={link?.isNewWindow}
                              underline={false}
                              className="text-sm"
                              anchorClassName="nav-drawer-link hover:nav-drawer-link-active focus:nav-drawer-link-active brand-ey:focus:font-normal brand-ey:focus:bg-tertiary-alt brand-ey:focus:border-b-primary-alt brand-ou:focus:bg-bg-light"
                              onClick={() => {
                                trackNavClickBasedOnLabel(subMenuLabel, label);
                                onClick?.();
                              }}
                            >
                              {subMenuLabel}
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </NavItemWithPopup>
                </Popup>
              );
            }
            return null;
          })}
        </ul>
      </div>
    </div>
  );
}

export default MegaMenu;
