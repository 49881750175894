import * as React from 'react';
import { PopupContext } from './popup.context';
import { focus } from './popup.utils';

export type Popup = {
  /**
   * The `id` is required and assigned to the PopupContent component. This is used for aria-controls on the PopupButton to get better accessibility compliance.
   */
  id: string;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'id'>;

/**
 * Presents more content in an expanded state after the user interacts with a trigger. Use `<Popup>` as a container around `<PopupButton>` and `<PopupContent>`.
 * Placement rules for the `<PopupContent>` are determined by its collision with the current viewport. The following rules determine the its position.
 *
 * | Collision | Position |
 * | --------- | ------ |
 * | bottom, top, right, left | middle, max-width |
 * | bottom, top, right | middle, no-max-width |
 * | bottom, top, left | middle, no-max-width |
 * | bottom, top | middle, no-max-width |
 * | bottom | target-top |
 * | bottom, left | target-top |
 * | bottom, right | target-top |
 * | top | target-bottom |
 * | top, right, left | middle, max-width |
 * | top, right | target-bottom |
 * | top, left | target-bottom |
 * | right, left | target-bottom, max-width |
 * | right | target-bottom |
 * | left | target-bottom |
 */
export const Popup: React.FC<React.PropsWithChildren<Popup>> = ({ children, id }) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const buttonClickedRef = React.useRef(false);
  const popoverRef = React.useRef<HTMLSpanElement>(null);

  const closePopup = () => {
    setOpen(false);
    focus(buttonRef.current);
  };

  return (
    <PopupContext.Provider
      value={{
        buttonRef,
        buttonClickedRef,
        popoverRef,
        popupId: id,
        isOpen,
        setOpen,
        closePopup,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};

export default Popup;
