import * as React from 'react';
import { Dialog } from '@dx-ui/osc-dialog';
import { useTranslation } from 'next-i18next';
import type { LoginResponse } from '@dx-ui/osc-login';
import { LoginIFrame } from '@dx-ui/osc-login';
import { sendInteractionReward } from '../header.utilities';

type TNavItemWithModal = {
  /** Specify the title for the Dialog */
  title: string;
  /** callback to run after login attempt */
  onLoginAttempt: (response: LoginResponse) => Promise<void>;
  /** Name of the button to display */
  buttonName?: string;
  /** aria-label of the button */
  buttonLabel?: string;
  buttonChildren: React.ReactNode;
  onClick?: () => void;
  theme?: Exclude<CmsBrandComponentTheme, 'light'>;
};

export const NavItemWithModal: React.FC<React.PropsWithChildren<TNavItemWithModal>> = ({
  buttonName,
  buttonLabel,
  onClick,
  title,
  onLoginAttempt,
  buttonChildren,
  children,
}) => {
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const open = () => {
    sendInteractionReward();
    onClick?.();
    setShowDialog(true);
  };
  const close = () => setShowDialog(false);

  const handleLogin = async (response: LoginResponse) => {
    await onLoginAttempt?.(response);
    if (!response.error) {
      close();
    }
  };

  const arrayChildren = React.Children.toArray(children);

  const [t] = useTranslation('osc-header');

  return (
    <>
      <button
        type="button"
        className="group mx-1 flex h-full items-center whitespace-nowrap text-sm font-bold"
        aria-label={buttonLabel || buttonName || t('signIn')}
        onClick={open}
      >
        {buttonChildren}
      </button>
      <Dialog
        ariaLabel={buttonName || t('signIn')}
        isOpen={showDialog}
        onDismiss={close}
        size="md"
        title={title}
      >
        {React.Children.map(arrayChildren, (child) => {
          if (React.isValidElement(child) && child.type === LoginIFrame) {
            return React.cloneElement(
              child as React.ReactElement<React.ComponentProps<typeof LoginIFrame>>,
              {
                onLoginAttempt: handleLogin,
                onClose: close,
              }
            );
          }
          return child;
        })}
      </Dialog>
    </>
  );
};

export default NavItemWithModal;
