import {
  GetHotelLanguageSelectorDataDocument,
  GetLanguageSelectorOneLinkConfigDocument,
} from './lib/gql/queries';

export * from './lib/osc-language-selector';
export * from './lib/gql/queries';
export * from './lib/gql/types';

export const GET_HOTEL_LANGUAGE_SELECTOR_DATA_ORIGINAL_OP_NAME =
  GetHotelLanguageSelectorDataDocument.originalOpName;
export const GET_LANGUAGE_SELECTOR_ONE_LINK_CONFIG_DOCUMENT_ORIGINAL_OP_NAME =
  GetLanguageSelectorOneLinkConfigDocument.originalOpName;

export const LANGUAGE_SELECTOR_ORIGINAL_OP_NAMES = [
  GET_HOTEL_LANGUAGE_SELECTOR_DATA_ORIGINAL_OP_NAME,
  GET_LANGUAGE_SELECTOR_ONE_LINK_CONFIG_DOCUMENT_ORIGINAL_OP_NAME,
];
