import { Link } from '@dx-ui/osc-link';
import { Menu, MenuButton, MenuLink, MenuList } from '@reach/menu-button';
import type { PRect } from '@reach/rect';
import { useRect } from '@reach/rect';
import cx from 'classnames';
import * as React from 'react';
import { useTranslation } from 'next-i18next';
import type { LoginProps, LoginResponse } from '@dx-ui/osc-login';
import { Login } from '@dx-ui/osc-login';
import Icon from '@dx-ui/osc-icon';
import { LanguageSelector } from '@dx-ui/osc-language-selector';
import type { TDrawerItem, TDrawerLink } from './brand-header-body/brand-header-body';
import { BrandHeaderBody } from './brand-header-body/brand-header-body';
import { sendInteractionReward } from './header.utilities';
import { sendReward } from '@dx-ui/framework-conductrics';
import { HeaderLogoLink } from './header.logo';

export type { TDrawerItem, TDrawerLink };

export type HeaderProps = {
  /** Brand url information */
  brand: {
    url: string;
    name: string;
    code: string;
    target?: React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >['target'];
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  };
  children?: React.ReactNode;
  findStayLink?: Link;
  /** Flag to control fluidity */
  isFluid?: boolean;
  /** Navigation links for the header */
  mainNavLinks?: Link[];
  /** Links used for the mega navigation */
  megaNavLinks?: TDrawerItem[];
  /** Sign out callback */
  onSignOut: () => Promise<void>;
  /** Sign in callback */
  onSignInAttempt: (response: LoginResponse) => Promise<void>;
  /** Flag to show new header */
  showNewHeader?: boolean;
  /** Flag to suppress the logo */
  suppressLogo?: boolean;
  /** Theming options for header */
  theme?: Exclude<CmsBrandComponentTheme, 'light'>;
  /** User information */
  user?: {
    name: string;
    honorsTier: string;
    honorsPoints: number;
    honorsPointsFmt: string;
    hhonorsNumber?: string;
  };
  /** User Links */
  userLinks?: UserLinks;
  loginOptions?: Pick<LoginProps, 'options'>;
  languageSelectorOptions?: React.ComponentProps<typeof LanguageSelector>;
} & React.HTMLAttributes<HTMLDivElement>;

export type UserLinks = {
  additionalLinks?: Link[];
  signInLink?: Link;
  signUpLink?: Link;
  accountLink?: Link;
};

const HeaderMenuLink: React.FunctionComponent<React.PropsWithChildren<Link>> = (link) => {
  const [t] = useTranslation('osc-header');
  return (
    <MenuLink
      href={link.url}
      onClick={link.onClick}
      className={cx(
        'text-text hover:text-text-alt highlighted:bg-bg-alt block px-2 py-3',
        link.className
      )}
      {...(link.adaDescription && {
        'aria-label': `${link.adaDescription}${link.isNewWindow ? `, ${t('newTab')}` : ''}`,
      })}
      {...(link.isNewWindow && { target: '_blank', rel: 'noopener noreferrer' })}
    >
      {link.label}
      {link.isNewWindow && !link.adaDescription ? (
        <span className="sr-only">
          , <span>{t('newTab')}</span>
        </span>
      ) : null}
    </MenuLink>
  );
};

export const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
  (
    {
      megaNavLinks,
      brand,
      findStayLink,
      isFluid,
      mainNavLinks,
      onSignOut,
      onSignInAttempt,
      suppressLogo = false,
      user,
      userLinks,
      loginOptions,
      children,
      languageSelectorOptions,
      showNewHeader,
      theme,
      ...rest
    },
    forwardedRef
  ) => {
    const [t] = useTranslation('osc-header');
    const defaultOptions = {
      frameSrc: userLinks?.signInLink?.url || '',
      title: t('signIn'),
      onLoginAttempt: onSignInAttempt,
    };
    const commonHeaderBodyProps = {
      brand,
      isFluid,
      mainNavLinks,
      onSignOut,
      onSignInAttempt,
      suppressLogo,
      user,
      userLinks,
      loginOptions,
      children,
    };

    const isDark = theme === 'dark';

    return (
      <>
        <a href="#skipped" className="btn-primary-text btn-xl sr-only focus:not-sr-only">
          {t('skipToContent')}
        </a>
        <header
          className={cx('border-border border-b border-solid', {
            'bg-bg-dark': isDark,
          })}
          data-testid="shared-header"
          ref={forwardedRef}
          {...rest}
        >
          {megaNavLinks || showNewHeader ? (
            <BrandHeaderBody
              menuItems={megaNavLinks}
              brand={brand}
              onSignInAttempt={onSignInAttempt}
              onSignOut={onSignOut}
              wrapperId="BrandAppWrapper"
              user={user}
              userLinks={userLinks}
              loginOptions={loginOptions}
              findStayLink={findStayLink}
              languageSelectorOptions={languageSelectorOptions}
              isFluid={isFluid}
              suppressLogo={suppressLogo}
              theme={theme}
            />
          ) : languageSelectorOptions ? (
            <NewHeaderBody
              {...commonHeaderBodyProps}
              defaultOptions={defaultOptions}
              languageSelectorOptions={languageSelectorOptions}
            />
          ) : (
            <OldHeaderBody {...commonHeaderBodyProps} defaultOptions={defaultOptions} />
          )}
        </header>
        {children}
        <div id="skipped" tabIndex={-1} />
      </>
    );
  }
);

type BodyProps = Pick<
  HeaderProps,
  | 'brand'
  | 'isFluid'
  | 'mainNavLinks'
  | 'onSignOut'
  | 'suppressLogo'
  | 'user'
  | 'userLinks'
  | 'loginOptions'
> & {
  defaultOptions: {
    frameSrc: string;
    title: string;
    onLoginAttempt: HeaderProps['onSignInAttempt'];
  };
};

function OldHeaderBody({
  brand,
  isFluid,
  mainNavLinks,
  onSignOut,
  suppressLogo,
  user,
  userLinks,
  loginOptions,
  defaultOptions,
}: BodyProps) {
  return (
    <div
      className={cx('flex flex-wrap items-center pt-2 lg:py-2', {
        'container-fluid': isFluid,
        container: !isFluid,
      })}
    >
      {suppressLogo ? null : <HeaderLogoLink brand={brand} />}
      {mainNavLinks?.length ? <MainNav links={mainNavLinks} suppressLogo={suppressLogo} /> : null}
      <UserNav
        user={user}
        userLinks={userLinks}
        onSignOut={onSignOut}
        loginOptions={loginOptions}
        defaultOptions={defaultOptions}
      />
    </div>
  );
}

function NewHeaderBody({
  brand,
  isFluid,
  mainNavLinks,
  onSignOut,
  suppressLogo,
  user,
  userLinks,
  loginOptions,
  defaultOptions,
  languageSelectorOptions,
}: BodyProps & {
  languageSelectorOptions: NonNullable<HeaderProps['languageSelectorOptions']>;
}) {
  return (
    <div
      className={cx('flex flex-wrap pt-1.5', {
        'container-fluid': isFluid,
        container: !isFluid,
      })}
    >
      <div className="flex w-full flex-wrap items-center justify-between gap-x-5 gap-y-2 pb-2 sm:justify-end sm:gap-x-12 sm:pb-0">
        <LanguageSelector {...languageSelectorOptions} />
        <div className="flex flex-wrap justify-end gap-x-2 gap-y-1.5 sm:flex-nowrap sm:gap-x-3">
          <UserNav
            user={user}
            userLinks={userLinks}
            onSignOut={onSignOut}
            loginOptions={loginOptions}
            defaultOptions={defaultOptions}
          />
        </div>
      </div>
      <div className="w-full items-center lg:flex lg:pb-2">
        {suppressLogo ? null : <HeaderLogoLink brand={brand} />}
        {mainNavLinks?.length ? <MainNav links={mainNavLinks} suppressLogo={suppressLogo} /> : null}
      </div>
    </div>
  );
}

function UserNav({
  user,
  userLinks,
  onSignOut,
  loginOptions,
  defaultOptions,
}: Pick<BodyProps, 'user' | 'userLinks' | 'onSignOut' | 'loginOptions' | 'defaultOptions'>) {
  const [t] = useTranslation('osc-header');
  return (
    <nav aria-label="Hilton Honors" className="relative ms-auto">
      {user ? (
        <Menu>
          <MenuButton
            onClick={() => {
              sendInteractionReward();
              sendReward('profile-name-click');
            }}
            className="btn-text-text btn-xl flex items-center"
          >
            <span className="brand-wa:font-normal">{t('greetings', { username: user.name })}</span>
            <Icon name="user-circle" size="md" variant="solid" className="ms-2" />
          </MenuButton>
          <MenuList
            portal={false}
            className="bg-bg border-border divide-border absolute end-0 z-50 w-56 divide-y rounded border border-solid p-2 shadow-lg outline-none"
          >
            <div className="flex flex-col px-2 py-3">
              <p className="leading-tight">
                <span className="block font-bold">{user.honorsTier}</span>
                <span>
                  {t('points', {
                    count: user.honorsPoints,
                    points: user.honorsPointsFmt,
                  })}
                </span>
              </p>
            </div>
            {userLinks?.accountLink?.url ? (
              <HeaderMenuLink
                onClick={(e) => {
                  userLinks.accountLink?.onClick?.(e);
                  sendInteractionReward();
                }}
                {...userLinks.accountLink}
                label={t('account')}
              />
            ) : null}
            {/* Optional user links */}
            {userLinks?.additionalLinks?.length
              ? userLinks.additionalLinks.map((link) => (
                  <HeaderMenuLink
                    onClick={(e) => {
                      link.onClick?.(e);
                      sendInteractionReward();
                    }}
                    key={link.label}
                    {...link}
                  />
                ))
              : null}
            <button
              className={cx(
                'text-text hover:text-text-alt highlighted:bg-bg-alt w-full text-start px-2 py-3'
              )}
              onClick={onSignOut}
              id="sign-out"
              type="button"
            >
              {t('signOut')}
            </button>
          </MenuList>
        </Menu>
      ) : userLinks?.signInLink || userLinks?.signUpLink ? (
        <ul className="divide-border flex items-baseline divide-x rtl:divide-x-reverse">
          {userLinks.signUpLink ? (
            <li className="px-2 first:ps-0 last:pe-0 sm:px-3">
              <Link
                {...userLinks.signUpLink}
                showNewWindowIcon={false}
                data-osc-product="header-signup-link"
                underline={false}
                className="btn btn-text-text sm:btn-xl brand-wa:!font-normal flex items-center sm:inline-flex"
                onClick={(e) => {
                  userLinks.signUpLink?.onClick?.(e);
                  sendReward('nav-join-click');
                  sendInteractionReward();
                }}
              >
                {t('signUp')}
              </Link>
            </li>
          ) : null}
          {userLinks.signInLink ? (
            <li className="px-2 first:ps-0 last:pe-0 sm:px-3">
              <Login onOpen={sendInteractionReward} {...{ ...defaultOptions, ...loginOptions }} />
            </li>
          ) : null}
        </ul>
      ) : null}
    </nav>
  );
}

function MainNav({
  links,
  suppressLogo,
}: {
  links: Link[];
  suppressLogo: HeaderProps['suppressLogo'];
}) {
  const navRef = React.useRef<HTMLDivElement>(null);
  const [isBeforeVisible, setBeforeVisible] = React.useState(false);
  const [isAfterVisible, setAfterVisible] = React.useState(false);

  const onChange = React.useCallback((rect: PRect) => {
    const scrollLeft = navRef.current?.scrollLeft ?? 0;
    const scrollWidth = navRef.current?.scrollWidth ?? 0;
    const navWidth = rect?.width || 0;
    const widthDiff = scrollWidth - navWidth;
    const newBefore = scrollLeft > 5 && widthDiff > 0;
    const newAfter = widthDiff > 0 && widthDiff - scrollLeft > 5;
    setBeforeVisible(newBefore);
    setAfterVisible(newAfter);
  }, []);
  const navRect = useRect(navRef, { onChange });
  const onNavScroll = React.useCallback(() => navRect && onChange(navRect), [onChange, navRect]);
  return (
    <nav
      className={cx(
        'flex-basis-full border-border lg:flex-basis-auto relative order-last mt-2 overflow-hidden border-t border-solid py-2 lg:order-none lg:mt-0 lg:flex-grow lg:overflow-auto lg:border-t-0 -mx-4 px-4 sm:-mx-8 sm:px-8 lg:mx-0 lg:px-4',
        {
          'lg:ps-0': suppressLogo,
          'header-nav-before': isBeforeVisible,
          'header-nav-after': isAfterVisible,
        }
      )}
    >
      <div
        ref={navRef}
        onScroll={onNavScroll}
        className="w-full overflow-x-scroll py-1 lg:overflow-x-visible lg:py-0"
      >
        <ul className="flex flex-nowrap items-center space-x-6 rtl:space-x-reverse">
          {links.map(({ label, ...linkProps }) => (
            <li
              key={`main-nav-link-${label}`}
              className="focus-within:text-text-alt hover:text-text-alt text-text"
            >
              <Link
                {...linkProps}
                underline={false}
                className="brand-wa:font-normal whitespace-nowrap text-base font-bold"
                onClick={(e) => {
                  linkProps.onClick?.(e);
                  sendInteractionReward();
                }}
              >
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

Header.displayName = 'Header';

export default Header;
