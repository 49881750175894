import cx from 'classnames';
import type * as React from 'react';

type LinkAdChoices = React.SVGProps<SVGSVGElement>;

const LinkAdChoices: React.FC<React.PropsWithChildren<LinkAdChoices>> = ({
  className,
  width = 14,
  height = 14,
  ...rest
}) => (
  <svg
    role="img"
    aria-hidden
    viewBox="0 0 26 26"
    width={width}
    height={height}
    className={cx('ms-1 inline-block fill-current', {
      [className as string]: !!className,
    })}
    data-testid="link-ad-choices"
    {...rest}
  >
    <path d="M2.193.663l-.222.008-.21.05-.201.067-.183.087-.172.114-.153.135-.144.162-.126.184-.115.19-.086.232-.077.24-.047.258-.03.278-.008.298v15.981l.009.373.029.345.047.307.066.279.088.24.095.21.124.183.135.154.154.124.164.095.173.068.192.04.2.009.221-.01.221-.029.24-.056.239-.079.259-.095.26-.124.268-.135 1.698-.95.096-.067.115-.087.115-.114.124-.135.117-.155.095-.171.068-.183.047-.2v-.222l-.029-.153-.067-.201-.096-.173-.115-.144-.135-.105-.164-.087-.171-.048-.19-.029-.192.01-.212.03-.117.03-.2.094-.192.068-.181.037-.174.01-.153-.018-.144-.04-.135-.068-.115-.096-.097-.114-.086-.142-.067-.155-.058-.184-.037-.2-.02-.211V5.162l.01-.221.039-.2.047-.183.068-.163.097-.144.104-.117.115-.095.143-.077.144-.049.164-.026h.162l.183.026.192.05.191.076.163.086 10.245 5.853.192.113.154.135.135.125.104.144.087.144.048.146.029.142-.01.152-.027.146-.06.142-.075.145-.116.135-.132.135-.164.115-.183.105-6.331 3.3v-3.876l-.019-.223-.067-.199-.097-.192-.133-.155-.164-.132-.183-.096-.2-.068-.21-.02-.223.02-.2.068-.191.096-.155.132-.134.164-.096.183-.068.2-.018.222v5.488l.018.21.057.182.096.173.126.163.144.144.161.124.185.106.191.086.106.039.144.038.181.02.193.009.21-.03.192-.057.095-.039 9.92-5.382.268-.144.24-.16.212-.156.19-.162.164-.163.134-.173.116-.163.086-.172.059-.182.029-.173v-.183l-.042-.181-.056-.183-.096-.184-.124-.191-.164-.183-.19-.192-.232-.18-.257-.192-.3-.183L3.69 1.094 3.422.95l-.26-.115-.259-.077L2.664.7 2.422.663z" />
    <path d="M8.764 8.51v-.087l-.038-.21-.077-.201-.106-.174-.133-.162-.164-.126-.192-.095-.203-.058-.219-.019h-.087l-.21.039-.201.065-.174.115-.162.135-.124.165-.097.191-.058.2-.02.222v.086l.04.21.067.203.115.171.134.154.162.135.192.095.202.06.221.016h.086l.212-.036.2-.077.172-.106.155-.135.135-.162.095-.192.057-.201z" />
  </svg>
);

export { LinkAdChoices };
export default LinkAdChoices;
