import { Login } from '@dx-ui/osc-login';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Link } from '@dx-ui/osc-link';
import FocusLock from 'react-focus-lock';
import { DrawerPanel } from '@dx-ui/osc-drawer';
import cx from 'classnames';
import MegaMenu from './mega-menu';
import { DrawerList } from './nav-drawer-list';
import Icon from '@dx-ui/osc-icon';
import type { HeaderProps } from '../header';
import {
  sendInteractionReward,
  trackNavClickBasedOnLabel,
  trackNavClick,
} from '../header.utilities';
import { LanguageSelector } from '@dx-ui/osc-language-selector';
import { sendReward } from '@dx-ui/framework-conductrics';
import { HeaderLogoLink } from '../header.logo';

export type TDrawerLink = {
  label: string;
  link: {
    url: string;
    isNewWindow: boolean;
    showNewWindowIcon: boolean;
  };
  onClick?: () => void;
};

export type TDrawerItem = {
  label: string;
  link?: TDrawerLink['link'];
  subMenu?: TDrawerLink[];
  onClick?: () => void;
};

export type TBrandHeaderBody = Pick<
  HeaderProps,
  | 'brand'
  | 'onSignOut'
  | 'onSignInAttempt'
  | 'user'
  | 'userLinks'
  | 'loginOptions'
  | 'languageSelectorOptions'
  | 'isFluid'
  | 'suppressLogo'
  | 'theme'
> & {
  menuItems?: TDrawerItem[];
  /** URL for path to Find Stay */
  findStayLink?: Link;
  /** Id of an element that wraps your app. required for selecting items to aria-hide when nav windows are open.
   * Open to other implementation suggestions here.
   * This is to combat virtual cursor in some screen readers focusing elements behind the nav.
   */
  wrapperId: string;
};

export const BrandHeaderBody = ({
  menuItems,
  brand,
  loginOptions,
  user,
  userLinks,
  findStayLink,
  wrapperId,
  onSignInAttempt,
  languageSelectorOptions,
  onSignOut,
  isFluid,
  suppressLogo,
  theme,
}: TBrandHeaderBody) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const { t } = useTranslation('osc-header');

  const closeMenus = () => {
    setIsMenuOpen(false);
    setIsAccountOpen(false);
    document.removeEventListener('keydown', handleKeyDown);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      closeMenus();
    }
  };

  const addEscapeKeyListener = () => {
    document.addEventListener('keydown', handleKeyDown);
  };

  const removeEscapeKeyListener = () => {
    document.removeEventListener('keydown', handleKeyDown);
  };

  const menuPressed = () => {
    sendInteractionReward();
    if (isAccountOpen) setIsAccountOpen(false);
    if (isMenuOpen) {
      setIsMenuOpen(false);
      removeEscapeKeyListener();
    } else {
      addEscapeKeyListener();
      setIsMenuOpen(true);
    }
  };

  const accountPressed = () => {
    sendInteractionReward();
    sendReward('profile-name-click');
    setIsMenuOpen(false);
    if (isAccountOpen) {
      setIsAccountOpen(false);
      removeEscapeKeyListener();
    } else {
      setIsAccountOpen(true);
      addEscapeKeyListener();
    }
  };

  const closeMainMenu = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = 'auto';
  };

  const closeAccount = () => {
    setIsAccountOpen(false);
    document.body.style.overflow = 'auto';
  };

  const isDark = theme === 'dark';

  return (
    <>
      <nav
        id="drawer-nav"
        className={cx('bg-bg z-50 lg:hidden', {
          'bg-bg brand-ou:bg-secondary': !theme,
          'bg-bg-dark': isDark,
        })}
      >
        <div
          className={cx('flex content-center items-center p-2 md:px-8 lg:px-10', {
            'sm:h-16': !!languageSelectorOptions,
            'h-16': !languageSelectorOptions,
          })}
        >
          {menuItems ? (
            <button
              className={cx('w-12 me-2', {
                'self-end sm:self-center': !!languageSelectorOptions,
              })}
              onClick={menuPressed}
              type="button"
              aria-expanded={isMenuOpen}
              id="menu-open-button"
            >
              <Icon
                name="menu"
                size="md"
                className={cx('m-auto block', {
                  'text-text-inverse': isDark,
                })}
              />
              <span
                className={cx('brand-wa:font-normal brand-ey:font-normal text-xs font-semibold', {
                  'text-text-inverse': isDark,
                })}
              >
                <span className="sr-only">{t('open')}</span>
                {t('menu')}
              </span>
            </button>
          ) : null}
          {!suppressLogo ? (
            <HeaderLogoLink
              theme={theme}
              brand={brand}
              className={cx({
                'self-end sm:self-center': !!languageSelectorOptions,
              })}
            />
          ) : null}
          <div className="ms-auto flex flex-col items-center gap-x-2 sm:flex-row">
            {languageSelectorOptions ? (
              <div className="pb-5 sm:pb-0">
                <LanguageSelector theme={theme} {...languageSelectorOptions} />
              </div>
            ) : null}
            <div className="ms-auto flex items-center gap-x-2">
              {findStayLink ? (
                <Link
                  className="mb-1"
                  anchorClassName="text-center w-16 align-middle"
                  underline={false}
                  url={findStayLink.url}
                  isNewWindow={findStayLink.isNewWindow}
                  showNewWindowIcon={false}
                  onClick={() => {
                    sendInteractionReward();
                    sendReward('find-stay-click');
                    trackNavClick('gh_findstay');
                  }}
                >
                  <Icon
                    name="calendar"
                    size="md"
                    className={cx('m-auto block', {
                      'nav-icon': !theme,
                      'text-text-inverse': isDark,
                    })}
                  />
                  <span
                    className={cx(
                      'brand-wa:font-normal brand-ey:font-normal text-xs font-semibold',
                      {
                        'text-text-inverse': isDark,
                      }
                    )}
                  >
                    {t('findStay')}
                  </span>
                </Link>
              ) : null}
              {user ? (
                <button
                  className="items-center"
                  onClick={() => {
                    trackNavClick('gh_membername');
                    accountPressed();
                  }}
                  aria-expanded={isAccountOpen}
                  type="button"
                >
                  <Icon
                    name="user-circle"
                    size="md"
                    className={cx('m-auto block', {
                      'nav-icon': !theme,
                      'nav-icon-dark': isDark,
                    })}
                  />
                  <span
                    className={cx(
                      'brand-ey:font-normal brand-wa:font-normal break-all text-xs font-semibold',
                      {
                        'text-text-inverse': isDark,
                      }
                    )}
                  >
                    {t('greetings', { username: user.name })}
                  </span>
                </button>
              ) : userLinks?.signInLink || userLinks?.signUpLink ? (
                <div className="flex flex-col">
                  <Icon
                    name="user-circle"
                    size="md"
                    className={cx('m-auto block', {
                      'nav-icon': !theme,
                      'nav-icon-dark': isDark,
                    })}
                  />
                  <div className="flex items-center">
                    {userLinks?.signUpLink ? (
                      <div className="h-fit">
                        <Link
                          {...userLinks.signUpLink}
                          showNewWindowIcon={false}
                          underline={false}
                          className={cx(
                            'brand-wa:font-normal brand-ey:font-normal border-border brand-lx:border-primary m-auto inline-block border-e pe-2 text-xs font-semibold',
                            {
                              'text-text-inverse': isDark,
                            }
                          )}
                          onClick={() => {
                            sendInteractionReward();
                            sendReward('nav-join-click');
                            trackNavClick('gh_join_signin');
                          }}
                        >
                          {t('signUp')}
                        </Link>
                      </div>
                    ) : null}
                    {userLinks.signInLink ? (
                      <div
                        className="ms-2"
                        onClick={() => {
                          sendInteractionReward();
                          trackNavClick('gh_join_signin');
                        }}
                      >
                        <Login
                          frameSrc={userLinks?.signInLink?.url || ''}
                          title={t('signIn')}
                          onLoginAttempt={onSignInAttempt}
                          hideIcon={true}
                          onOpen={() => {
                            setIsMenuOpen(false);
                            setIsAccountOpen(false);
                          }}
                          buttonClassName={cx(
                            'brand-wa:!font-normal brand-ey:font-normal font-semibold text-xs',
                            {
                              'text-text-inverse': isDark,
                            }
                          )}
                          {...loginOptions}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {(isMenuOpen || isAccountOpen) && (
          <div
            className="bg-overlay animate-fadein absolute top-0 z-50 h-screen w-full overflow-hidden backdrop-blur-[2px]"
            data-testid="backgroundOverlay"
            onClick={closeMenus}
          />
        )}
        {menuItems ? (
          <div
            data-testid="menuWindow"
            className={cx({ visible: isMenuOpen, invisible: !isMenuOpen })}
          >
            <DrawerPanel
              isOpen={isMenuOpen}
              alignment="left"
              appWrapperId={wrapperId}
              interactiveElementWrapperId="drawer-nav"
              returnFocus={() => {
                document.getElementById('menu-open-button')?.focus();
                return false;
              }}
            >
              <DrawerList isOpen={isMenuOpen} menuItems={menuItems} onClose={closeMainMenu} />
            </DrawerPanel>
          </div>
        ) : null}
        <DrawerPanel
          isOpen={isAccountOpen}
          alignment="right"
          appWrapperId={wrapperId}
          interactiveElementWrapperId="drawer-nav"
        >
          {user && isAccountOpen ? (
            <AccountSection
              user={user}
              userLinks={userLinks}
              onClose={closeAccount}
              onSignOut={onSignOut}
            />
          ) : null}
        </DrawerPanel>
      </nav>

      <nav className="hidden lg:block">
        <MegaMenu
          isFluid={isFluid}
          suppressLogo={suppressLogo}
          brand={brand}
          onSignInAttempt={onSignInAttempt}
          onSignOut={onSignOut}
          user={user}
          userLinks={userLinks}
          mainNavLinks={menuItems}
          findStayLink={findStayLink}
          languageSelectorOptions={languageSelectorOptions}
          loginOptions={loginOptions}
          theme={theme}
        />
      </nav>
    </>
  );
};

export type TAccountSection = Pick<TBrandHeaderBody, 'userLinks' | 'onSignOut'> &
  Required<Pick<TBrandHeaderBody, 'user'>> & {
    onClose: () => void;
  };

const AccountSection = ({ user, userLinks, onClose, onSignOut }: TAccountSection) => {
  const { t } = useTranslation('osc-header');

  return (
    <FocusLock returnFocus>
      <div className="border-border brand-ey:border-primary-alt brand-ou:border-primary relative mb-2 border-b px-4 py-3">
        <button onClick={onClose} type="button" className="px-2">
          <Icon name="close" size="md" className="m-auto" />
          <span className="brand-ey:font-normal brand-wa:font-normal text-xs font-semibold">
            {t('close')}
            <span className="sr-only">{t('closeAccountDetails')}</span>
          </span>
        </button>
      </div>
      <div className="flex">
        <div className="px-4">
          <div className="bg-hilton-alt flex size-12 items-center justify-center rounded-full p-2">
            <Icon name="user" size="xl" />
          </div>
        </div>
        <div>
          <span className="brand-ey:font-normal font-bold">
            {t('greetings', { username: user.name })}
          </span>
          <div className="capitalize">
            <span className="brand-ey:font-normal font-bold underline">{user.honorsTier}</span>{' '}
            {t('status')}
          </div>
          <div>{t('honorsPointsTotal', { honorsPointsTotal: user.honorsPointsFmt })}</div>
          {user.hhonorsNumber && (
            <div>{t('hiltonHonorsNumber', { hhonorsNumber: user.hhonorsNumber })}</div>
          )}
        </div>
      </div>
      <div className="border-border brand-ey:border-primary-alt brand-ou:border-primary mt-4 divide-y divide-solid border-y">
        {userLinks?.accountLink?.url ? (
          <Link
            anchorClassName={cx(
              'block py-2 px-6 border-border',
              'brand-ey:border-primary-alt brand-ey:hover:bg-tertiary-alt brand-ey:focus:bg-tertiary-alt',
              'brand-lx:hover:bg-bg-light brand-lx:focus:bg-bg-light',
              'brand-ou:border-primary brand-ou:hover:bg-bg-light brand-ou:focus:bg-bg-light'
            )}
            className="brand-ey:font-normal text-text font-bold"
            underline={false}
            url={userLinks.accountLink.url}
            onClick={() => {
              trackNavClick('gh_membername_hiltonhonorshome');
            }}
          >
            {t('hiltonHonorsHome')}
          </Link>
        ) : null}
        {userLinks?.additionalLinks
          ?.filter((link) => link.label)
          .map((link) => (
            <Link
              anchorClassName={cx(
                'block py-2 px-6 border-border',
                'brand-ey:border-primary-alt brand-ey:hover:bg-tertiary-alt brand-ey:focus:bg-tertiary-alt',
                'brand-lx:hover:bg-bg-light brand-lx:focus:bg-bg-light',
                'brand-ou:border-primary brand-ou:hover:bg-bg-light brand-ou:focus:bg-bg-light'
              )}
              className="brand-ey:font-normal text-text font-bold"
              underline={false}
              key={link.label}
              {...link}
              onClick={() => {
                link.label && trackNavClickBasedOnLabel(link.label, 'membername');
              }}
            >
              {link.label}
            </Link>
          ))}
        <button
          className={cx(
            'text-text border-border block w-full px-6 py-2 text-left font-bold rtl:text-start',
            'brand-ey:font-normal brand-ey:border-primary-alt brand-ey:hover:bg-tertiary-alt brand-ey:focus:bg-tertiary-alt',
            'brand-lx:hover:bg-bg-light brand-lx:focus:bg-bg-light',
            'brand-ou:border-primary brand-ou:hover:bg-bg-light brand-ou:focus:bg-bg-light'
          )}
          onClick={async () => {
            trackNavClick('gh_membername_signout');
            onClose();
            await onSignOut();
          }}
          type="button"
        >
          {t('signOut')}
        </button>
      </div>
    </FocusLock>
  );
};
