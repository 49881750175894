import * as React from 'react';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import cx from 'classnames';

import { LinkAdChoices } from './link.ad-choices';
import { LinkNewWindow } from './link.new-window';
import { getCIDParam } from './link.utils';
import { useIsClient } from 'usehooks-ts';

export type LinkBaseProps = {
  /** jsx icon element to display with link */
  icon?: JSX.Element;
  /**
   * Set the `aria-label` for the link
   */
  adaDescription?: string;
  /**
   * Set the classNames for the anchor element
   */
  anchorClassName?: string;
  children?: React.ReactNode;
  /** required props for dynamic cid param */
  cidParams?: {
    campaignId: string;
    componentName: string;
  };
  /** Set to true if app supports next link routing in Akamai */
  hasNextLinkSupport?: boolean;
  /**
   * Sets the `target` attribute to `_blank`
   */
  isNewWindow?: boolean;
  /**
   * The name of the link
   */
  label?: string;
  /**
   * Set the `href` attribute for the link
   */
  url: string;
  /**
   * Whether or not the new window icon should be visible
   */
  showNewWindowIcon?: boolean;
  /** whether or not the link should be underlined */
  underline?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrapper?: any;
};

export type Link = LinkBaseProps & Omit<React.ComponentProps<typeof NextLink>, 'href'>;

export const Link = React.forwardRef<HTMLAnchorElement, Link>((props, forwardedRef) => {
  const {
    adaDescription,
    icon,
    children,
    cidParams: { campaignId, componentName } = {},
    hasNextLinkSupport,
    id,
    isNewWindow,
    target,
    url: urlProp,
    className = 'text-sm text-primary hover:text-primary-alt',
    anchorClassName,
    wrapper: Wrapper,
    showNewWindowIcon = true,
    underline = true,
    ...rest
  } = props;
  useIsClient();
  const { t } = useTranslation('osc-link');
  const isAdChoices = !!children?.toString().match(/adchoices/i);
  const isTargetBlank = isNewWindow || target === '_blank';
  const linkProps = rest;
  const canUseNextLink = hasNextLinkSupport && urlProp?.startsWith('/');
  const url = urlProp?.startsWith('http') ? new URL(urlProp) : urlProp;

  if (adaDescription) {
    linkProps['aria-label'] = isTargetBlank ? `${adaDescription}, ${t('newTab')}` : adaDescription;
  }
  if (isTargetBlank) {
    linkProps.rel = 'noopener noreferrer';
  }
  if (typeof url !== 'string' && campaignId && componentName && !url.searchParams.has('cid')) {
    const cid = getCIDParam({ campaignId, componentName });
    url.searchParams.set('cid', 'CID_VALUE_TO_BE_REPLACED');
    url.href = url.href.replace('CID_VALUE_TO_BE_REPLACED', cid);
  }

  const classNames = cx({
    underline,
    [className]: !!className,
  });

  const linkContent = React.createElement(
    canUseNextLink ? NextLink : 'a',
    {
      ref: forwardedRef,
      id,
      target: isTargetBlank ? '_blank' : target,
      href: url,
      className: cx({
        [anchorClassName as string]: !!anchorClassName,
        'inline-block text-pretty': !anchorClassName,
      }),
      ...linkProps,
    },
    <>
      <span className={classNames}>
        {children}
        {(isAdChoices || isTargetBlank) && isAdChoices ? (
          <LinkAdChoices />
        ) : (
          isTargetBlank && showNewWindowIcon && <LinkNewWindow />
        )}
        {!!icon && <span aria-hidden>{icon}</span>}
      </span>
      {isTargetBlank && (
        <span className="sr-only">
          , <span>{t('newTab')}</span>
        </span>
      )}
    </>
  );

  // eslint-disable-next-line no-nested-ternary
  return url ? (
    Wrapper ? (
      <Wrapper href={url} {...props}>
        {linkContent}
      </Wrapper>
    ) : (
      linkContent
    )
  ) : (
    <div
      ref={forwardedRef as unknown as React.LegacyRef<HTMLDivElement>}
      className={classNames}
      id={id}
      aria-hidden={rest['aria-hidden']}
    >
      {children}
    </div>
  );
});

Link.displayName = 'Link';

export default Link;
